import * as Swal from "sweetalert2";

window.Main = new (function () {

    var $windowWidth = $(window).width();
    var that = this;

    //Mobile functions
    let destructor = function () {
        $('.navSite').detach().appendTo('.DskNavBox');
        $('body').removeClass('noScroll');
    }

    let constructor = function () {
        $('.navSite').detach().prependTo('.ecoNav');
    }

    this.matchesMenu = function () {
        let breackpoint = 1023,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        mediaqueryresponse(mql)

        mql.addListener(mediaqueryresponse)

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                constructor();
            } else {
                destructor();
            }
        }
    }

    //open-close menu
    this.menuToggle = function () {
        $('body').toggleClass('noScroll');
    }

    /**
     * Init
     */
    this.init = function () {

        $('.goTo').on('click', that.scrollToElement);
        //mobile menu
        that.matchesMenu();

        $('.hamburger').on('click', that.menuToggle);

        that.headerClass();

    };

    this.scrollToElement = function () {
        let goToElement = $(this).data('place');

        $('.eco-navLeft li a').removeClass('active');
        $(this).addClass('active');

        $('.hamburger').trigger('click');

        $('html, body').animate({
            scrollTop: $("#" + goToElement).offset().top
        }, 1000);
    };

    this.unSlick = function () {
        try {
            $('.slick-maintenance').slick('unslick');
        } catch (e) {
            console.log(e);
        }

    };

    //add class to header on scroll
    this.headerClass = function () {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 15) {
                $('header').addClass('stick')
            } else {
                $('header').removeClass('stick')
            }
            if ($('body').hasClass('is_team')) {
                $('header').addClass('stick');
            }
        })
    }

    this.home = function () {
        try {
            $('.slick-trajectory').slick({
                dots: true,
                arrows: false,
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 99999,
                },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                        }
                    }]
            });
        } catch (e) {
            console.log(e);
        }
    };

    this.us = function () {
        try {
            $('.slider-our-values').slick({
                dots: true,
                arrows: false,
                infinite: false,
                autoplay: true,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        } catch (e) {
            console.log(e);
        }
    };

    this.projects = function () {
        //listener for projects tabs
        $('.principal-projects-menu .menu-content .menu-item button').on('click', that.changeProjectTab);

        //listener projects acordeon
        $('.wrap-project-content .tab-content .main-wrap-title .wrap-text').on('click', that.showHideDesarrollo);
        $('.wrap-project-content .tab-content .main-wrap-title .wrap-text:first').trigger('click');
    };

    this.changeProjectTab = function () {
        let currentTab = $(this);
        let changeTabTo = $(this).parent().data('tab');

        $('.principal-projects-menu .menu-content .menu-item').removeClass('current');
        $(currentTab).parent().addClass('current');

        $('.principal-projects-content .wrap-content .wrap-project-content').removeClass('current');
        $('.principal-projects-content .wrap-content #' + changeTabTo).addClass('current');

        $('.wrap-project-content .tab-content .main-wrap-title .wrap-text').removeClass('current');
        $('.wrap-project-content.current .tab-content .main-wrap-title .wrap-text:first').trigger('click');
    };

    this.showHideDesarrollo = function () {
        let currentElement = $(this);
        let descriptionToShow = $(this).data('id');

        let breackpoint = 1023,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        if (mql.matches) {

            if ($(this).hasClass('current')) {
                $(currentElement).removeClass('current');
                $('#' + descriptionToShow).removeClass('current');
                return;
            }

            $('.wrap-project-content.current .tab-content .main-wrap-title .wrap-text').removeClass('current');
            $('.wrap-project-content.current .tab-content .main-wrap-title .wrap-description-text').removeClass('current');

            $(currentElement).addClass('current');
            $('#' + descriptionToShow).addClass('current');
        } else {
            $('.wrap-project-content.current .tab-content .main-wrap-title .wrap-text').removeClass('current');
            $(currentElement).addClass('current');

            let theText = $(currentElement).data('description');
            $('.wrap-display-description .wrap-w-text').html('');
            $('.wrap-display-description .wrap-w-text').html(theText);
        }

    };

    this.uma = function () {

        $('.change-palma').on('change', that.changeUMAPalma);
    };

    this.changeUMAPalma = function () {
        let showPalma = $(this).val();

        $('.wrap-dynamic-info .item').removeClass('active');
        $('.wrap-dynamic-info .item.' + showPalma).addClass('active');
    };

})();

$(window).resize(function () {
    var $windowWidth = $(window).width();
    if ($windowWidth > 991) {
        //Main.unSlick();
    } else {
        //Main.init();
    }
});
